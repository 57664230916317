import React, { Component } from 'react';
import CustomProduct from './CustomProduct';
import { isMobile } from "react-device-detect";
import { serverResponse } from '../../util/fakeServer';

export default class CustomSlide extends Component {

    render() {

        const containerClass = {
            position: "relative",
            display: "inline-block",
            // width: "100%",
            overflow: "hidden",
            borderTopLeftRadius: serverResponse.config.show_one_page ? "0" : this.props.pageNumber % 2 == 0 ? "0" : "11px",
            borderTopRightRadius: serverResponse.config.show_one_page ? "0" : this.props.pageNumber % 2 == 0 ? "11px" : "0",
            borderBottomLeftRadius: serverResponse.config.show_one_page ? "0" : this.props.pageNumber % 2 == 0 ? "0" : "11px",
            borderBottomRightRadius: serverResponse.config.show_one_page ? "0" : this.props.pageNumber % 2 == 0 ? "11px" : "0",
            boxShadow: "0 0.5rem 1rem rgba(0,0,0,0.5)",
        }

        const imgClass = {
            maxHeight: "calc(100vh - 100px)",
            // minHeight: "500px",
            maxWidth: "100%"
        }

        const imgMobileClass = {
            width: "100%"
        }

        let align_page = "left";
        if (this.props.pageNumber % 2 == 0) {
            align_page = "right";
        }
        if (!serverResponse.leaflet.index) {
            if (this.props.pageNumber % 2 == 0) {
                align_page = "left";
            }
            else
            {
                align_page = "right";
            }
        }

        if(isMobile || serverResponse.config.show_one_page){
            align_page = 'center';
        }

        const wrapperClass = {
            width: "100%",
            textAlign: align_page,
        }

        const listProducts = this.props.products.map((element, key) =>
            <CustomProduct disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={element} leafletSwipe={this.props.leafletSwipe} isSwipeDisabed={this.props.isSwipeDisabed} setSwipe={this.props.setSwipe} />
        )

        return (
            <div style={wrapperClass}>
                <div style={containerClass} className="slide-shadow">
                    <img className="checkMobileClassImg" loading="lazy" src={process.env.PUBLIC_URL + this.props.imagePath + "?v=" + serverResponse.config.version} style={isMobile ? imgMobileClass : imgClass} alt="" />

                    {listProducts}

                </div>
            </div>
        );
    }
}