const leaflets = {
    "demo": {
        "config": {
            "primary_color": "#00B2F3",
            "secondary_color": "#035E99",
            "tertiary_color": "#D4232C",
            "hover_color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": '/media/images/logo.png',
            "serverPath": "santacaterinavillage.interattivo.net/",
            "pdfPath": "/media/pdf/catalogo.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo catalogo di Interattivo!",
            "shareFlyerURL": "https://santacaterinavillage.interattivo.net?v=606",
            "shareProductSentence": "Guarda questo fantastico Catalogo!",
            "ogTitleMeta": "Santa Caterina Village | Catalogo Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo catalogo.",
            "ogImageMeta": "/media/images/share_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-38",
            "release_id": "1",
            "send_grocery_list_to_market": true,
            "grocery_list_min_value": 10,
            "client_id": 38,
            "signboard_id": 60,
            "has_pages": true,
            "type": "catalog",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true,
            "pager": "buttons",
            "hide_share_product": false,
            "hide_grocery_list": true,
            "show_one_page": true,
            "version": 606
        },
        "leaflet": {
            "name": "Demo Santa Caterina Village",
            "code": "606",
            "id_campaign": "606",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/Indice-demo-scaterina_wlv6WCX_hiBLceB.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 35.595690747782,
                            "left": 7.877059569074775,
                            "width": 84.26489226869455,
                            "height": 6.882129277566541
                        }
                    },
                    {
                        "page": 3,
                        "blueprint": {
                            "top": 42.477820025348535,
                            "left": 7.877059569074775,
                            "width": 84.26489226869455,
                            "height": 7.946768060836501
                        }
                    },
                    {
                        "page": 5,
                        "blueprint": {
                            "top": 50.42458808618505,
                            "left": 7.877059569074775,
                            "width": 84.26489226869455,
                            "height": 7.896070975918886
                        }
                    },
                    {
                        "page": 7,
                        "blueprint": {
                            "top": 58.320659062103935,
                            "left": 7.877059569074775,
                            "width": 84.26489226869455,
                            "height": 7.566539923954372
                        }
                    },
                    {
                        "page": 9,
                        "blueprint": {
                            "top": 65.88719898605832,
                            "left": 7.877059569074775,
                            "width": 84.26489226869455,
                            "height": 7.7693282636248435
                        }
                    },
                    {
                        "page": 1,
                        "blueprint": {
                            "top": 78.45930232558139,
                            "left": 33.11046511627907,
                            "width": 34.21511627906977,
                            "height": 7.906976744186048
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Sport e Tempo Libero",
                    "subcategories": [
                        "Altro"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_1_113UJlc.jpg",
                    "products": [
                        {
                            "item_id": "cstm30614759",
                            "field1": "Video",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Video",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 21.43864203314863,
                                "left": 0,
                                "width": 100,
                                "height": 57.242726103665056
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_t0BGJ4H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/video/Santa_Caterina_Village__-_Scalea_-_Calabria.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_4_Mm5wnoA.jpg",
                    "products": [
                        {
                            "item_id": "cstm98030994",
                            "field1": "Prenota ora",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Prenota ora",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 54.84548821093047,
                                "left": 20.257266891850318,
                                "width": 22.220723904971063,
                                "height": 8.040802249170229
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_DSKsA4N_qLMzU0r.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.simplebooking.it/ibe/hotelbooking/search?hid=3590&lang=it"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm30634233",
                            "field1": "Una vacanza da vivere.",
                            "field2": "Il Santa Caterina Village & Resort",
                            "field3": "",
                            "field4": "È la location ideale dove trascorrere vacanze serene ed eventi speciali.",
                            "description": "Una vacanza da vivere. Il Santa Caterina Village & Resort È la location ideale dove trascorrere vacanze serene ed eventi speciali.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.040506797261758,
                                "left": 17.134352767857354,
                                "width": 76.63145948078694,
                                "height": 43.68436935246259
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DSC01939--villaggio--Santa-Caterina-Village_yLWvdqb.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DJI-0039--3---villaggio--Santa-Caterina-Village_juZqQy6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DSC01728--animazione--Santa-Caterina-Village_BqtAowh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_3RfRifr_NaN1cXw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_5_T9sHMQ2.jpg",
                    "products": [
                        {
                            "item_id": "cstm04460791",
                            "field1": "Servizi",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Servizi",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 0,
                                "left": 0,
                                "width": 100,
                                "height": 100
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_3X5PPKX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_6_Luc82tM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_7_G7m5pwM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_8_6HnWbwl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_5_gj11ONk_lXrmu7P.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_mgmL7O4_LJOeCG0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il Santa Caterina Village è immerso in un rigoglioso parco privato che si affaccia direttamente sul mare ed è costituito da due corpi che ospitano 380 camere d’hotel, dotate di tutti quei comforts che faranno dire anche a te che qui da noi #ètuttaunaltravita. Il nostro hotel a Scalea è composto da due edifici, il CORPO RESORT direttamente sul mare ed il CORPO VILLAGE a soli 180 mt. dalla spiaggia, collegati tra loro da una galleria shopping privata, dotato di parcheggio privato interno, dotato di ben quattro piscine, due sale ristorante, e potrai praticare tanti sport in totale autonomia o sotto l’occhio vigile dei nostri istruttori.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/images_5_gj11ONk_lXrmu7P.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_6_Oj3dhRK.jpg",
                    "products": [
                        {
                            "item_id": "cstm03461424",
                            "field1": "Relax con stile.",
                            "field2": "Innovazione e raffinatezza nelle nostre camere Resort,",
                            "field3": "che sia vacanza o sia lavoro, si prenderanno cura di te.",
                            "field4": "E per un soggiorno indimenticabile, viziati con la vista mare.",
                            "description": "Relax con stile. Innovazione e raffinatezza nelle nostre camere Resort, che sia vacanza o sia lavoro, si prenderanno cura di te. E per un soggiorno indimenticabile, viziati con la vista mare.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 0,
                                "left": 0,
                                "width": 100,
                                "height": 100
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_3_uA0lrun.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_1_qT23CMV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_5_fAqhdcj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_4_BU1pTl1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_EWlboTq_EKgjX0m.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_7_LbOo32F.jpg",
                    "products": []
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_8_2JMZbPD.jpg",
                    "products": [
                        {
                            "item_id": "cstm60764537",
                            "field1": "Ristorazione",
                            "field2": "La Ristorazione è il nostro vanto",
                            "field3": "con trattamento in pensione completa e pasti serviti a buffet.",
                            "field4": "Siamo attenti anche ai piccoli ospiti con una ristorazione tutta per loro!",
                            "description": "Ristorazione La Ristorazione è il nostro vanto con trattamento in pensione completa e pasti serviti a buffet. Siamo attenti anche ai piccoli ospiti con una ristorazione tutta per loro!",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 3.96039603960396,
                                "left": 0,
                                "width": 100,
                                "height": 72.87728956001068
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DSC00464--ristorante--Santa-Caterina-Village_D2O4fEO.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DSC00331--ristorante--Santa-Caterina-Village_NcUtTVl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/DSC02270--ristorante--Santa-Caterina-Village_qFmFpb3.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/images_7_YfysUy4_kuHJGpD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_taanJC6_bpb1Tc8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_9_OHu9bbZ.jpg",
                    "products": [
                        {
                            "item_id": "cstm91179797",
                            "field1": "Santuario di San Francesco di Paola",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Santuario di San Francesco di Paola",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.337788812172573,
                                "left": 0,
                                "width": 37.32852369251818,
                                "height": 37.91729805596487
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/facciata_DDECC2d.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/portalebas_lWW662Y.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/macigno_u9heasc.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_ZWW6ClY_XNh7cHZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Curiosità",
                                        "testo": "Di fianco all'ingresso della Basilica è posta una grossa pietra corrosa dalla secolare devozione dei fedeli, che testimonia uno dei tanti miracoli operati dal Santo. Questa pietra è stata trasportata dal mare fino alla chiesa da un uomo infermo. Nonostante la menomazione, questo fedele obbedì all’invito del buon Frate e, strada facendo, la pietra diventò leggera e la sua gamba guarì.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/macigno_u9heasc.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm59850222",
                            "field1": "Golfo di Policastro",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Golfo di Policastro",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.337788812172573,
                                "left": 37.32852369251818,
                                "width": 35.09901906175725,
                                "height": 37.91729805596487
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_6_MQqf9Ob.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_5_l0ZEXuh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_4_7btZe2v.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_nKybR1b_WKtp0J2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/video/Golfo_di_Policastro.mp4"
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Curiosità",
                                        "testo": "Il golfo deve il suo nome a Policastro Bussentino. Ha il suo inizio a Punta degli Infreschi nel parco del Cilento e la fine a Scalea in Calabria. Chi ha visitato il Golfo di Policastro non può che essere rimasto affascinato dal suo incantevole mare, dalla rigogliosa natura del suo entroterra costituito dal bacino idrografico del fiume Bussento, dai panorami che le alture offrono generosamente e dai borghi medioevali che rimandano ad una storia ricca di eventi.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/download_6_MQqf9Ob.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm35849278",
                            "field1": "Il Cristo di Maratea",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Il Cristo di Maratea",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.337788812172573,
                                "left": 72.42754275427542,
                                "width": 27.572457245724582,
                                "height": 37.91729805596487
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_8_DvArlo0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_9_lsCAooj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_7_iHGz3rj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_YdrEAmC_VTnX3Kp.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Curiosità",
                                        "testo": "Situata nell'antica Maratea Superior , anche detta Castello, opera dello scultore fiorentino Bruno Innocenzi e voluta dal Conte Stefano Rivetti di Valcervo, la statua del Cristo Redentore di Maratea fu innalzata nel 1965 sulla sommità del Monte San Biagio nel luogo in cui si ergeva una croce commemorativa in pietra.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/download_8_DvArlo0.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm58093948",
                            "field1": "Isola di Dino (Praia a Mare)",
                            "field2": "a soli 10 km dal Santa Caterina Village.",
                            "field3": "",
                            "field4": "",
                            "description": "Isola di Dino (Praia a Mare) a soli 10 km dal Santa Caterina Village.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 43.25508686813744,
                                "left": 0,
                                "width": 100,
                                "height": 56.74491313186254
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_1acM3Ou_gBWaWuR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/video/Isola_di_Dino_Calabria_Praia_a_Mare__DJI_Mavic_Air_footage_4K_Italy.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_10_xZu5lJD.jpg",
                    "products": [
                        {
                            "item_id": "cstm97254271",
                            "field1": "Natura, arte e storia.",
                            "field2": "",
                            "field3": "Da non perdere le escursioni lungo la Riviera dei Cedri",
                            "field4": "o nell’entroterra nel Parco Nazionale del Pollino, in trekking, in rafting o in quad. ",
                            "description": "Natura, arte e storia. Da non perdere le escursioni lungo la Riviera dei Cedri o nell’entroterra nel Parco Nazionale del Pollino, in trekking, in rafting o in quad. ",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 43.25508686813744,
                                "left": 0,
                                "width": 100,
                                "height": 56.74491313186254
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_1_EJZboEz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_tokNAGW_n2WMVy9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_2_k11bc65.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/download_3_4Ju70Pz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_xjE3225_DindFLD.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm70854296",
                            "field1": "L’Arco Magno a San Nicola Arcella",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "L’Arco Magno a San Nicola Arcella",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.190512642572841,
                                "left": 49.12581624678581,
                                "width": 50.87418375321419,
                                "height": 38.0645742255646
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/San-Nicola-Arcella-Arcomagno-14_qQyyQZo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/San-Nicola-Arcella-Arcomagno-13_g2dDqoN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/San-Nicola-Arcella-Arcomagno-10_7mKpAJQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_gWPb8EL_JYoHRXJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Curiosità",
                                        "testo": "La località è così denominata per lo straordinario arco naturale venutosi a creare nella dura roccia, scavato probabilmente dal mare, aprendo così la via verso una piccola spiaggia, che è un piccolo paradiso.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/San-Nicola-Arcella-Arcomagno-10_7mKpAJQ.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm07473315",
                            "field1": "Rafting sul fiume Lao nel Parco Nazionale del Pollino",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Rafting sul fiume Lao nel Parco Nazionale del Pollino",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 5.190512642572841,
                                "left": 0,
                                "width": 49.12581624678581,
                                "height": 38.0645742255646
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/000144-e1594216430395_hxQMN3F.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/IMG_4156-e1594209126698_h0blC0t.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/IMG_5412-e1594216303796_tRPw2cQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_bXgM61e_YPqoTt0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "Descrizione",
                                        "testo": "Il rafting é sport, natura e divertimento, un'esperienza coinvolgente, un'attività che permette a tutti di entrare in contatto con il fiume e provare il brivido delle rapide, anche le più impegnative, in totale sicurezza. Grazie alle nostre guide (certificate dalla Federazione Italiana Rafting). Il rafting sul Lao coinvolge i 5 sensi, trasmettendo emozioni intense ed indimenticabili, suscitate dalle meraviglie del fiume Lao.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/images/IMG_4156-e1594209126698_h0blC0t.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_11_kp74r5f.jpg",
                    "products": []
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/page_12_ZVmMuXt.jpg",
                    "products": [
                        {
                            "item_id": "cstm79749476",
                            "field1": "Prenota ora",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Prenota ora",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 27.006003346916724,
                                "left": 30.003003962505627,
                                "width": 20.192011876968948,
                                "height": 7.171439398883722
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_iCOtYa8_yRiZ52q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.simplebooking.it/ibe/hotelbooking/search?hid=3590&lang=it"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm69945731",
                            "field1": "Mappa",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Mappa",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 57.12901473252793,
                                "left": 61.08611044209891,
                                "width": 38.91388955790111,
                                "height": 42.87098526747207
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_EWNQKsJ_YTIDVyo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://g.page/SantaCaterinaVillage?share"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm67640526",
                            "field1": "Facebook",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Facebook",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 56.52565805896997,
                                "left": 28.802880288028803,
                                "width": 21.242124212421242,
                                "height": 6.000601891243812
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_mI6IXxD_62J5hUj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.facebook.com/SantaCaterinaVillage/"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm45733792",
                            "field1": "Whatsapp",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Whatsapp",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 75.36754041615099,
                                "left": 22.922294060460732,
                                "width": 31.563152653155946,
                                "height": 7.800781909245613
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/products/cropped_image_iZ8pSvS_zo4fjdN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "tel:0985043105"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/IoVaPuHiJM/pages/Fine-scaterina_FOYEP7Y.png",
                    "products": []
                }
            ]
        }

    }
}

const lefletSwitch = () => {
    return leaflets.demo;
}

export const serverResponse = lefletSwitch();